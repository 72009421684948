export default {
  data() {
    return {
      loadingFlipbase: true
    };
  },
  methods: {
    lazyLoadFlipbaseRecorder() {
      return new Promise((resolve, reject) => {
        if (!window.Flipbase || !window.Flipbase.recorder) {
          let recorderScript = document.createElement('script');
          recorderScript.onload = () => {
            this.loadingFlipbase = false;
            return resolve();
          };
          recorderScript.async = true;
          recorderScript.src = 'https://app.flipbase.com/recorder.js';
          document.head.appendChild(recorderScript);
        } else {
          this.loadingFlipbase = false;
          return resolve();
        }
      });
    },
    lazyLoadFlipbasePlayer() {
      return new Promise((resolve, reject) => {
        if (!window.Flipbase || !window.Flipbase.player) {
          let playerScript = document.createElement('script');
          playerScript.onload = () => {
            this.loadingFlipbase = false;
            return resolve();
          };
          playerScript.async = true;
          playerScript.src = 'https://cdn.flipbase.com/player/v2/player.min.js';
          document.head.appendChild(playerScript);
        } else {
          this.loadingFlipbase = false;
          return resolve();
        }
      });
    }
  }
};
