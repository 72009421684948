<template>
  <div class="container">
    <div class="pt-5" v-if="loading">
      <loader></loader>
    </div>
    <div v-else>
      <div class="row mt-4 pb-4" v-if="data">
        <div class="col-12" v-if="data.dateCompleted">
          <small class="float-right">{{ 'general.submittedOn' | translate }} {{ data.dateCompleted }}</small>
        </div>
        <div class="col-md-3 col-sm-4 profile-image-container">
          <el-upload
            class="avatar-uploader"
            :auto-upload="false"
            action
            accept="image/*"
            :multiple="false"
            :on-change="handleUploadChange"
            :show-file-list="false"
          >
            <img
              @error="imageNotFound"
              v-if="avatar"
              :src="avatar"
              class="avatar rounded-circle"
              style="max-width: 100%"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <primary-button class="btn-block mt-3" :title="$t('settings.uploadTooltip')">{{
              'settings.uploadPhoto' | translate
            }}</primary-button>
          </el-upload>
        </div>
        <div class="col-sm-8 col-md-6 pl-24">
          <h4 class="font-weight-bold mb-4">{{ data.title }}</h4>
          <h6 :style="{ color: style.introductionTextColor }">{{ data.description || data.defaultText }}</h6>
          <form @submit.prevent="submit(true)">
            <div class="form-group mt-4">
              <textarea
                :readonly="data.dateCompleted || loading"
                class="form-control"
                rows="6"
                name="user-introduction"
                ng-minlength="6"
                v-model="data.userIntroduction"
                :required="checkIfRequired"
                :placeholder="data.defaultText"
                v-if="!data.dateCompleted"
              ></textarea>
              <p class="introduction-text mt-2 mb-4" v-if="data.dateCompleted" v-html="data.userIntroduction"></p>
            </div>
            <default-button
              v-if="!data.dateCompleted && data.showFlipbase"
              type="button"
              :loading="updating"
              @click="reset"
              >{{ 'general.reset' | translate }}</default-button
            >
            <div>
              <template v-if="!loadingFlipbase" v-once>
                <br />
                <input
                  v-if="data.showFlipbase && !data.flipbaseId"
                  type="flipbase"
                  name="flipbase"
                  v-model="flipbaseInput"
                  id="flipbase-recorder"
                />
                <div id="video-player-element"></div>
                <br />
              </template>
            </div>
            <div class="form-group" v-if="data.showLinkedinUrl">
              <div class="row">
                <div class="col-2 col-lg-1 mt-2 pt-1">
                  <span class="social-icons icon-circle icon-zoom">
                    <i
                      class="t-icon bold-53_logos-social_medias-linkedin"
                      :style="{ color: style.introductionIconColor }"
                    ></i>
                  </span>
                </div>
                <div class="col-10 col-lg-11">
                  <input
                    class="form-control"
                    :readonly="data.dateCompleted || loading"
                    type="text"
                    v-model="data.linkedinUrl"
                    placeholder="http://www.linkedin.com/in/..."
                  />
                </div>
              </div>
            </div>
            <div class="form-group" v-if="data.showFacebookUrl">
              <div class="row">
                <div class="col-2 col-lg-1 mt-2 pt-1">
                  <span class="social-icons icon-circle icon-zoom">
                    <i
                      class="t-icon bold-53_logos-social_medias-social_media_facebook"
                      :style="{ color: style.introductionIconColor }"
                    ></i>
                  </span>
                </div>
                <div class="col-10 col-lg-11">
                  <input
                    class="form-control"
                    :readonly="data.dateCompleted || loading"
                    type="text"
                    v-model="data.facebookUrl"
                    placeholder="http://www.facebook.com/..."
                  />
                </div>
              </div>
            </div>
            <div class="form-group" v-if="data.showTwitterUrl">
              <div class="row">
                <div class="col-2 col-lg-1 mt-2 pt-1">
                  <span class="social-icons icon-circle icon-zoom">
                    <i
                      class="t-icon bold-53_logos-social_medias-twitter"
                      :style="{ color: style.introductionIconColor }"
                    ></i>
                  </span>
                </div>
                <div class="col-10 col-lg-11">
                  <input
                    class="form-control"
                    :readonly="data.dateCompleted || loading"
                    type="text"
                    v-model="data.twitterUrl"
                    placeholder="http://www.twitter.com/..."
                  />
                </div>
              </div>
            </div>
            <template v-if="!data.dateCompleted">
              <div>
                <default-button
                  v-if="!data.dateCompleted && !data.showFlipbase"
                  type="button"
                  :loading="updating"
                  @click="reset"
                  >{{ 'general.reset' | translate }}</default-button
                >
                <secondary-button
                  type="button"
                  :class="!data.showFlipbase && `ml-2`"
                  :loading="updating"
                  @click="save"
                  >{{ 'general.save' | translate }}</secondary-button
                >
              </div>
              <br />
              <p class="mb-2" :style="{ color: style.introductionTextColor }">
                {{ 'introduction.readyToSend' | translate }}
              </p>
              <primary-button class type="submit" :loading="updating">{{ 'general.send' | translate }}</primary-button>
            </template>
            <template v-else>
              <secondary-button class="float-right" @click="backToDashboard" type="button">{{
                'general.returnToDashboard' | translate
              }}</secondary-button>
            </template>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

import { mapGetters } from 'vuex';
import { Upload } from 'element-ui';
import flipbaseMixin from '../../mixins/flipbase';
import planhatMixin from '../../mixins/planhat';
import resizeImageMixin from '@app/mixins/resize-image';
import fixImageOrientationMixin from '@app/mixins/fix-image-orientation';

import femaleAvatar from '@shared/assets/img/avatar-w.png';
import maleAvatar from '@shared/assets/img/avatar-m.png';

export default {
  mixins: [fixImageOrientationMixin, flipbaseMixin, resizeImageMixin, planhatMixin],
  components: {
    elUpload: Upload
  },
  mounted() {
    this.$http.get(`dashboard/introduceyourselfs/${this.$route.params.id}`).then(
      (response) => {
        this.data = response.data;
        if (this.data && this.data.dateCompleted) {
          this.data.dateCompleted = new Date(this.data.dateCompleted).toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          }); // moment(this.data.dateCompleted).format("DD/MM/YYYY");
        }
        // load flipbase
        if (this.data.showFlipbase) {
          this.$http.get(`companies/${this.user.companyId}/flipbase`).then((flipbaseResponse) => {
            const flipbaseData = flipbaseResponse.data;
            this.flipbaseData = flipbaseData;
            if (!flipbaseData.recorderId || !flipbaseData.playerId) {
              this.data.showFlipbase = false;
            } else {
              // no flipbase video recorded yet
              if (!this.data.flipbaseId) {
                this.loadFlipbaseRecorder();
              } else {
                this.loadFlipbasePlayer();
              }
            }
          });
        }
        this.loading = false;
      },
      (err) => {
        this.$notify({
          type: 'error',
          message: this.$t('notifications.error'),
          position: 'bottom-right'
        });
        this.loading = false;
        this.$router.go(-1); // go back by one record, the same as history.back()
      }
    );
  },
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters('settings', ['company', 'style']),
    avatar() {
      const avatar = this.user.gender === 2 ? femaleAvatar : maleAvatar;
      return this.photo || this.user.imageURL || avatar;
    },
    checkIfRequired() {
      return this.data.showFlipbase && this.data.flipbaseId ? false : true;
    }
  },
  data() {
    return {
      data: null,
      flipbaseData: null,
      flipbaseInput: null,
      photo: null,
      loading: true,
      languages: [],
      updating: false,
      flipbasePlayer: null
    };
  },
  methods: {
    backToDashboard() {
      this.$router.push({ name: 'dashboard' });
    },
    async handleUploadChange(fileObj) {
      let file = fileObj.raw;
      const size = file.size;
      const sizeInKb = size / 1024; // Convert the bytes to Kilobytes (1 KB = 1024 Bytes)
      const sizeInMb = sizeInKb / 1024; // Convert the KB to MegaBytes (1 MB = 1024 KBytes)
      if (sizeInMb > 20) {
        return this.$notify({
          type: 'error',
          message: this.$t('notifications.wrongPictureFormat'),
          position: 'bottom-right'
        });
      }

      this.fixImageOrientation(file, async (fixedFile) => {
        file = fixedFile;

        const config = {
          file,
          size: 500
        };

        const resizedImage = await this.resizeImage(config, true);
        this.photo = resizedImage;
        this.data.UserImage = resizedImage;
        this.$forceUpdate();
      });
    },
    reset() {
      this.data.linkedinUrl = null;
      this.data.facebookUrl = null;
      this.data.twitterUrl = null;
      this.data.userIntroduction = null;
    },
    loadFlipbaseRecorder() {
      this.lazyLoadFlipbaseRecorder().then(() => {
        setTimeout(() => {
          window.Flipbase.recorder({
            recorderId: this.flipbaseData.recorderId,
            maxHeight: 400,
            locale: this.user.language,
            duration: 60,
            backgroundColor: '#FFF',
            primaryColor: '#bfbfbf',
            secondaryColor: '#fff',
            textColor: '#000',
            callback: (id) => {
              this.data.flipbaseId = id;
            }
          });
        }, 400);
      });
    },
    loadFlipbasePlayer() {
      this.lazyLoadFlipbasePlayer().then(() => {
        setTimeout(() => {
          this.flipbaseplayer = new FlipbasePlayer({
            video_id: this.data.flipbaseId,
            player_id: this.flipbaseData.playerId,
            selector: 'video-player-element'
          });
          this.flipbaseplayer.mount();
        }, 600);
      });
    },
    imageNotFound(event) {
      event.target.src = this.user.gender === 2 ? femaleAvatar : maleAvatar;
    },
    save() {
      this.submit(false);
    },
    submit(sendToManager = true) {
      if (this.data.showFlipbase && !this.data.flipbaseId) {
        if (window.SALES) {
          return alert('Not available in demo');
        }
      }
      this.data.id = this.$route.params.id;
      if (this.data.UserImage) {
        this.data.imageURL = this.data.UserImage;
      }

      this.loading = true;
      this.updating = true;
      this.data.sendToManager = sendToManager;
      this.$http.post('dashboard/introduceyourselfs', this.data).then(
        () => {
          this.loading = false;
          this.updating = false;
          if (this.data.UserImage) {
            this.$store.dispatch('auth/setUserImage', this.data.UserImage);
          }
          if (sendToManager) {
            this.$notify({
              type: 'success',
              message: this.$t('notifications.dataUpdated'),
              position: 'bottom-right'
            });
            //Planhat introduce yourself event succeeded, description is optional
            if (this.data.userIntroduction !== null && this.data.flipbaseId === null) {
              this.track('tal_introduce_yourself_text');
            } else {
              this.track('tal_introduce_yourself_video');
            }
            this.$router.push({ name: 'dashboard' });
          } else {
            this.$notify({
              type: 'success',
              message: this.$t('notifications.introductionDataSaved'),
              position: 'bottom-right'
            });
            if (this.data.showFlipbase) {
              if (!this.data.flipbaseId) {
                this.loadFlipbaseRecorder();
              } else {
                this.loadFlipbasePlayer();
              }
            }
          }
        },
        (err) => {
          if (err !== 'demo') {
            this.$notify({
              type: 'error',
              message: this.$t('notifications.error'),
              position: 'bottom-right'
            });
          }
          this.updating = false;
          setTimeout(() => {
            this.loading = false;
          }, 3000);
        }
      );
    }
  }
};
</script>

<style lang="scss">
input.el-upload__input {
  display: none !important;
}
</style>

<style lang="scss" scoped>
@import '@shared/styles/_helpers.scss';

label {
  font-weight: bold;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: $general-border-radius;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 142px;
  height: 142px;
  display: block;
}
#flipbase-recorder {
  display: none;
}
@media (min-width: 567px) and (max-width: 992px) {
  .profile-image-container {
    width: 50%;
    margin-bottom: 24px;
  }
}

@media (max-width: 992px) {
  .profile-image-container {
    margin-bottom: 24px;
  }
}
</style>
